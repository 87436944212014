import React, {Component} from 'react';
import ChatPage from './ChatPage';
import {useParams} from 'react-router-dom';
import {Card, CardContent, CircularProgress, Typography, Grid,Accordion,AccordionSummary,AccordionDetails, Button} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const FamilySelectionWrapper = ({Component}) => {
    const {userId} = useParams();
    return <Component userId={userId}/>;
};

class FamilySelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            familyId: '',
            families: [],
            managing:[],
            covering:[],
            assistantName: localStorage.getItem('assistantName') ? localStorage.getItem('assistantName') : '',
            hasFetched: false,
            unreadMessageCount: [],
            openChatPage:false,
            sectionsOpen: { families: true, managing: false,covering:false, },

        };
    }

    componentDidMount() {

        const storedFamilies = localStorage.getItem('Families');
        
        if (storedFamilies) {
            const combinedDataParsed = JSON.parse(storedFamilies);
            const parsedFamilies = combinedDataParsed.families; 
            const parsedManaging = combinedDataParsed.managing;
            const parsedCovering=combinedDataParsed.covering;
            this.setState({
                families: parsedFamilies,managing:parsedManaging,covering:parsedCovering, hasFetched: true,
            }, () => {
                this.getAssistantFamilies();
                
            });
        } else {
            this.getAssistantFamilies();
        }
        this.syncStateWithServerFamily();
        this.initSyncInterval();
    }
    componentWillUnmount() {      
        clearInterval(this.syncInterval);
    }

    async getAssistantFamilies() {

        const {userId} = this.props;

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                staffID: userId,
            }),
        };

        await fetch(`${baseURL}/utils/fetchAssistantFamilies.php`, requestOptions)
            .then((response) => response.json())
            .then((data) => {


                localStorage.setItem('assistantName', data.Assistant_Name);
                const storedFamilies = this.state.families;
                const updatedFamilies = {
                    families: data.families,
                    managing: data.Managing,
                    covering:data.Covering_For,
                };
                if (!(JSON.stringify(storedFamilies) === JSON.stringify(updatedFamilies))) {

                    const combinedData = {
                        families: data.families,
                        managing: data.Managing,
                        covering:data.Covering_For,
                    };
                    localStorage.setItem('Families', JSON.stringify(combinedData));

                    this.setState({
                        families: data.families,managing:data.Managing,covering:data.Covering_For, assistantName: data.Assistant_Name, hasFetched: true,
                    }, () => {
                        
                    });
                }
            })
            .catch(error => {
                console.error(error);
            });

    }

    initSyncInterval() {
        
        this.syncInterval = setInterval(() => {
                this.syncStateWithServerFamily();
        }, 15000); // 15 seconds in milliseconds
    }

    syncStateWithServerFamily() {
        

        fetch(`${baseURL}/utils/pushNotifications.php?action=getAllUnreadCounts`, {
            method: 'POST', headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }).then(response => response.json())
            .then(data => {
                this.setState((prevState) => ({
                    unreadMessageCount: {
                        ...prevState.unreadMessageCount, ...data.unreadFamilyCount,
                    },
                }));
            })
            .catch(error => {
                console.error('Error fetching unread counts:', error);
            });
    }

    handleCardClick = (familyId) => {
        this.setState({familyId,openChatPage: true});
    };

    handleCloseChatPage = () => {
        this.setState({ openChatPage: false });
    };

    toggleSection = (section) => {
        this.setState(prevState => ({
            sectionsOpen: {
                ...prevState.sectionsOpen,
                [section]: !prevState.sectionsOpen[section],
            },
        }));
    };

    handleLogout = () => {
        localStorage.clear();
        window.location.href = '/';
      };

    renderFamilies(families) {
        const { unreadMessageCount} = this.state;
       
        const badgeContainerStyles = {
            marginLeft: 'auto',
            width: 20,
            height: 20,
            background: '#D74654',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }

        return (
            <Grid container spacing={2}>
            {families.map((family) => {
                const unreadFamilyMessageCount = unreadMessageCount[family.id];

                const familyInitials = family.Name.slice(0, 3).toUpperCase();

                return (<Grid key={family.id} item xs={12} sm={6} md={4} lg={3}>
                   
                        <Card style={{borderRadius: '15px', position: 'relative'}}
                              onClick={() => this.handleCardClick(family.id)}>
                            <CardContent>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div
                                        style={{
                                            marginRight: 8,
                                            width: 40,
                                            height: 40,
                                            background: '#D74654',
                                            borderRadius: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'white',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {familyInitials}
                                    </div>
                                    <div style={{marginTop: 8, textAlign: 'left'}}>
                                        <div>{family.Name}</div>
                                    </div>

                                    {unreadFamilyMessageCount > 0 && (<div
                                        style={badgeContainerStyles}>
                                        <div
                                            style={{color: 'white', fontSize: 12}}>
                                            {unreadFamilyMessageCount}
                                        </div>
                                    </div>)}
                                </div>
                            </CardContent>
                        </Card>
                 
                        </Grid>
                );
            })}
        </Grid>
        );
    };

    render() {
        const {families,managing,covering, hasFetched, assistantName,sectionsOpen} = this.state;
        const {userId} = this.props;

    const accordionStyle={
        marginBottom: '10px', 
        backgroundColor: '#e0e0e0', 
        boxShadow: 'none'

    }
      
        return (<div>
            { !this.state.openChatPage && !hasFetched ? (<CircularProgress/>) : (<>
                {!this.state.openChatPage && (
                    <>
                    <div style={{padding:'16px'}}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    {assistantName}'s Families
                                </Typography>
                                <Button variant="contained" color="primary" sx={{ textTransform: 'none' }}  onClick={this.handleLogout} >
                                    <LogoutIcon fontSize='small' sx={{ mr: 1 }} />  Logout
                                </Button>
                            </div>
                            {/* Families Section */}
                            <Accordion 
                            expanded={sectionsOpen.families} 
                            onChange={() => this.toggleSection('families')}
                            sx={accordionStyle} >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" >Families</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.renderFamilies(families)}
                                </AccordionDetails>
                            </Accordion>
                            {/* Managing Section */}
                            <Accordion 
                            expanded={sectionsOpen.managing} 
                            onChange={() => this.toggleSection('managing')}
                            sx={accordionStyle} >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" >Managing</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.renderFamilies(managing)}
                                </AccordionDetails>
                            </Accordion>
                             {/* Covering Section */}
                            <Accordion 
                            expanded={sectionsOpen.covering} 
                            onChange={() => this.toggleSection('covering')}
                            sx={accordionStyle} >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" >Covering For</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.renderFamilies(covering)}
                                </AccordionDetails>
                            </Accordion>
             
              
                </div>
                </>
            )}
                {this.state.openChatPage && (
                            <ChatPage
                                familyID={this.state.familyId}
                                userId={userId}
                                handleCloseChatPage={this.handleCloseChatPage}
                                managingIDs={this.state.managing}         
                            />
                        )}
            </>)}
            
            
        </div>);
    }
}

const WrappedFamilySelection = () => <FamilySelectionWrapper Component={FamilySelection}/>;

export default WrappedFamilySelection;
