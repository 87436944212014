import React, {Component} from 'react';

import {Box, CircularProgress, Fab, Typography} from "@mui/material";


class TaskCard extends Component {
    getRelevantIcon(chatTask) { //todo use the categories from a singular endpoint (see Completed Tasks)
        if (this.isPausedItem(chatTask.fields["Task - Status Text"])) {
            return <img height={36} width={36} src="/task-icons/pause.svg" alt="Icon"/>;
        } else if (this.isHoldItem()) {
            return <img height={36} width={36} src="/task-icons/hold.svg" alt="Icon"/>;
        } else {
            switch (chatTask.fields["Main Category"]) {
                case "Activities":
                    return <img src="/task-icons/activities.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Admin Finance and Legal":
                    return <img src="/task-icons/admin.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Birthdays and Events":
                    return <img src="/task-icons/birthday.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Dining":
                    return <img src="/task-icons/dining.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Education":
                    return <img src="/task-icons/education.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Gifts and Shopping":
                    return <img src="/task-icons/gifts.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Health and Wellbeing":
                    return <img src="/task-icons/health.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Holidays and Travel":
                    return <img src="/task-icons/holidays.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Home and Garden":
                    return <img src="/task-icons/home.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Family":
                    return <img src="/task-icons/family.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Pets":
                    return <img src="/task-icons/pets.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Real Estate":
                    return <img src="/task-icons/real-estate.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                case "Vehicles":
                    return <img src="/task-icons/vehicles.svg" alt="Icon" style={{filter: 'invert(50%)'}}/>;
                default:
                    return <img src="/task-icons/new-task-icon.png" alt="Icon" style={{filter: 'invert(50%)'}}/>;
            }
        }
    }


    isCompletedItem = (item) => {
        return (item.includes("Done") || item.includes("Feedback/Payment"));
    };

    isPausedItem = (item) => {
        return (item.includes("Info Needed") || item.includes("Info Needed from Client"));
    };

    isHoldItem = () => {
        return (this.props.isItemHold);
    };

    unreadMessage() {

        return this.props.unreadMessageCount || false;
    }

    render() {

        const {chatTask, showControls} = this.props;

        const cardStyle = {

            display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%', position: 'relative'
        };
        const TaskStatus = ({statusText}) => {
            let displayText = statusText;
            let color = "#888888";

            if (statusText.includes("Info Needed") || statusText.includes("Info Needed from Client")  ) {
                displayText = "Action Required";
                color = "#FF003D";
            } else if (statusText.includes("Done") || statusText.includes("Feedback/Payment")) {
                displayText = "Completed";
                if (chatTask.fields['receipt']) {
                    displayText = "Paid";
                }
            }

            return (<div style={{fontSize: 16, paddingTop: 4, paddingBottom: 16, fontFamily: 'Manrope', color}}>
                {displayText}
            </div>);
        };

        return (


            <Typography style={{...cardStyle}}>

                <div id="card-details"
                     style={{display: "flex", alignItems: "center", flex: 1, marginRight: 20}}>
                    <div style={{marginRight: 12, display: 'flex', alignItems: 'flex-end',}}>
                        {chatTask.fields["In Progress Percentage Rollup (from Task - Movement Log)"] || this.isCompletedItem(chatTask.fields["Task - Status Text"]) ?
                            <Box size={64}
                                 sx={{
                                     position: "relative", color: "#FFFFFF33",
                                 }}
                            >
                                <Fab
                                    sx={{
                                        height: 64, width: 64, backgroundColor: "transparent", "&:hover": {
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                >
                                    {this.getRelevantIcon(chatTask)}
                                </Fab>
                                <CircularProgress
                                    variant="determinate"
                                    size={64}
                                    value={100}
                                    sx={{
                                        color: this.isPausedItem(chatTask.fields["Task - Status Text"]) ? "#121212" : "#FFFFFF33",
                                        position: "absolute",
                                        left: 0,
                                        zIndex: 1,
                                    }}
                                />
                                <CircularProgress
                                    variant="determinate"
                                    size={64}
                                    value={this.isCompletedItem(chatTask.fields["Task - Status Text"]) ? 100 : Number(chatTask.fields["In Progress Percentage Rollup (from Task - Movement Log)"]
                                        .split(",")
                                        .map((percent) => (parseFloat(percent.trim()) * 100).toFixed(0))
                                        .filter((percent) => !isNaN(percent))
                                        .pop())}
                                    sx={{
                                        color: this.isPausedItem(chatTask.fields["Task - Status Text"]) ? "#FF003D" : this.isHoldItem() ? "#7A7A7A" : "#2F98A3",
                                        position: "absolute",
                                        left: 0,
                                        zIndex: 1,
                                    }}
                                />
                            </Box> : <Box size={64}
                                          sx={{
                                              position: "relative", color: "#FFFFFF33",
                                          }}
                            >

                                <Fab
                                    sx={{
                                        height: 64, width: 64, backgroundColor: "transparent", "&:hover": {
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                >

                                    {this.getRelevantIcon(chatTask)}

                                </Fab>
                                <CircularProgress
                                    variant="determinate"
                                    size={64}
                                    value={100}
                                    sx={{
                                        color: "#FFFFFF33", position: "absolute", left: 0, zIndex: 1,
                                    }}
                                />

                            </Box>}
                    </div>
                    <div>
                        <div style={{
                            fontSize: 16,
                            paddingTop: 4,
                            color: '#121212',
                            fontWeight: '500',
                            fontFamily: 'Manrope',
                            wordWrap: 'break-word'
                        }}>
                            {chatTask.fields["Title"]}
                        </div>
                        <div>
                            {chatTask.fields["Task - Status Text"].includes("New Task") ? null :
                                <TaskStatus statusText={chatTask.fields["Task - Status Text"]}/>}

                        </div>

                    </div>
                </div>
                {showControls && (<div id="card-controls"
                                       style={{
                                           display: 'flex', flexDirection: 'row', alignItems: 'right', zIndex: 1000
                                       }}>
                    <div style={{
                        width: 20,
                        height: 20,
                        background: this.unreadMessage() ? '#2F98A3' : 'transparent',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {this.unreadMessage() > 0 && (<div style={{
                            color: 'white',
                            fontSize: 12,
                            fontFamily: 'Manrope',
                            fontWeight: '500',
                            lineHeight: '20px',
                            wordWrap: 'break-word'
                        }}>
                            {this.unreadMessage()}
                        </div>)}
                    </div>
                </div>)}

            </Typography>);
    }
}

export default TaskCard;
