import React, {Component} from 'react';
import {List, ListItem, Paper, Tabs, Tab, CardContent, Typography, IconButton} from '@mui/material';
import TaskCard from './TaskCard';
import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const NOTIFICATIONS='notifications'
const GENERAL='General'
const MEM_PREFIX='mem_'

const useStyles = {
    drawer: {
        width: '370px', flexShrink: 0,

    }, drawerPaper: {
        width: '370px',


    }, sidebarStyles: {
        width: '370px', backgroundColor: '#FCFCFC', height: '100%', borderRadius: '15px'

    },

};

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            hasFetched: false,
            hasFetchedMembers: false,
            hasFetchedArchive: false,
            activeTab: 'taskChat',
            members: [],
            archivedTasks: [],
            staff: [],
        };
    }

    async componentDidMount() {
        await this.getFamilyData(this.handleMembersUpdate);
        this.fetchTasks();
        this.fetchArchivedTasks();
    }

    async fetchTasks() {
        const {familyID} = this.props;

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, function: "fetchClientTasks", admin: 1
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchClientTasks.php`, requestOptions);
            const data = await response.json();
            const {records} = data;

            const fetchedTasks = await Promise.all(records.map(async (record, index) => {

                record.index = index;
                record.isPinned = (record.fields["Urgent Task"] && record.fields["Task - Status Text"][0] !== "On Hold");
                return record;
            }));

            this.setState({
                hasFetched: true, tasks: fetchedTasks,
            });

        } catch (error) {
            console.error('Error:', error);
        }
    }

    async fetchArchivedTasks() {
        const {familyID} = this.props;


        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, function: "fetchClientTasks", done: 1, admin: 1
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchClientTasks.php`, requestOptions);
            const data = await response.json();
            const {records} = data;

            const fetchedTasks = await Promise.all(records.map(async (record, index) => {

                record.index = index;
                //uncomment below if you want to close chat for done tasks 
                // record.isArchived = true;
                return record;
            }));

            this.setState({
                hasFetchedArchive: true, archivedTasks: fetchedTasks,
            });

        } catch (error) {
            console.error('Error:', error);
        }
    }

    async fetchMembers(familyID) {
        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, function: 'fetchMemberID',
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchFamilyData.php`, requestOptions);
            return await response.json();
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    async fetchStaff() {
        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                function: 'fetchStaff',
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchFamilyData.php`, requestOptions);
            return await response.json();
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    async getFamilyData(onMembersUpdate) {

        const {familyID} = this.props;
        let members = [];
        let staff = [];

        members = await this.fetchMembers(familyID)
        staff = await this.fetchStaff();
        this.setState({
            members: members,
            staff:staff,
            hasFetchedMembers: true,
        }, () => {
            if (onMembersUpdate) {
                onMembersUpdate(this.state.members);
            }
        })
                 
    }

    handleTaskClick = (task) => {
        this.props.onItemClick(task, this.state.staff);
    };

    handleTabChange = (event, newValue) => {
        this.setState({activeTab: newValue});
    };

    handleGeneralClick = (member) => {
        let dataObject;
    
        if (member === NOTIFICATIONS) {
            dataObject = {
                id: NOTIFICATIONS
            };
        } else if (member === GENERAL) {
            dataObject = {
                id: GENERAL
            };
        } else {
            dataObject = {
                id: MEM_PREFIX + member.ID,
                fields: {
                    Title: member.Name,
                }
            };
        }
    
        this.handleTaskClick(dataObject);
    }
    
    handleMembersUpdate = (members) => {

        this.props.onMembersUpdate && this.props.onMembersUpdate(members);
    }
    handleRefresh = () => {
        this.setState({
            hasFetched: false, hasFetchedArchive: false, hasFetchedMembers: false
        }, () => {
            this.fetchTasks();
            this.getFamilyData(this.handleMembersUpdate);
            this.fetchArchivedTasks();
        })

    };

    onDragEnd = (result) => {
        if (!result.destination) return;

        const {source, destination} = result;
        const {tasks} = this.state;

        let newItems = [...tasks];
        let reorderedItems;

        let filterCondition = item => !item.isHold 
               
            const filteredItems = tasks.filter(filterCondition);
            reorderedItems = this.reorder(filteredItems, source.index, destination.index);
            
            let j = 0;
            for (let i = 0; i < newItems.length; i++) {
                if (filterCondition(newItems[i])) {
                    newItems[i] = reorderedItems[j];
                    j++;
                }
            }
            newItems.forEach((item, idx) => {
                item.index = idx;
            });

            this.setState({tasks: newItems});
            this.saveItemsOrder(newItems);
        }
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    saveItemsOrder = (items) => {

        const {familyID} = this.props;
        fetch(`${baseURL}/utils/saveOrder.php`, {
            method: "POST", body: JSON.stringify({familyID: familyID, items: items}),
        })
            .then((response) => response.text())
            .catch((error) => {
                console.error("There was an error saving the item order:", error);
            });
    };

    render() {
        const {tasks, hasFetched, activeTab, members, hasFetchedMembers, archivedTasks, hasFetchedArchive} = this.state;

        const cardStyle = {
            margin: '5px 2px 5px 0px',
            border: '1px solid #E0DEDE',
            minHeight: '100px',
            borderRadius: '16px',
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: '100%',
            position: 'relative'
        }


        const typographyStyle = {

            color: '#121212',
            fontFamily: 'Manrope',
            fontWeight: 600,
            textAlign: 'center',
            fontSize: '14px',
            margin: '10px',
        };

        const pinnedTasks = tasks.filter((task) => task.isPinned);
        const nonPinnedTasks = tasks.filter((task) => !task.isPinned);

        const totalUnreadTasks = Object.entries(this.props.unreadMessageCount)
            .filter(([key]) => !key.startsWith(MEM_PREFIX) && key !== GENERAL && !archivedTasks.some(task => task.id === key))
            .reduce((total, [, count]) => total + parseInt(count, 10), 0);

        const totalUnreadArchive = Object.entries(this.props.unreadMessageCount)
            .filter(([key]) => !key.startsWith(MEM_PREFIX) && key !== GENERAL && archivedTasks.some(task => task.id === key))
            .reduce((total, [, count]) => total + parseInt(count, 10), 0);

        const totalUnreadGeneral = Object.entries(this.props.unreadMessageCount)
            .filter(([key]) => key.startsWith(MEM_PREFIX) || key === GENERAL)
            .reduce((total, [, count]) => total + parseInt(count, 10), 0);


        return (<>
                <div

                    style={useStyles.drawer}
                    classes={{
                        paper: useStyles.drawerPaper,
                    }}
                    anchor="left"
                    position="fixed"
                >
                    <Paper style={useStyles.sidebarStyles}>
                        <Box display="flex" justifyContent="center">

                            <IconButton onClick={this.handleRefresh} style={{color: 'grey'}}>
                                <RefreshIcon style={{fontSize: '1rem'}}/>
                            </IconButton>
                            <Tabs
                                value={activeTab}
                                onChange={this.handleTabChange}
                                TabIndicatorProps={{
                                    style: {
                                        border: '1px solid green', background: 'green',
                                    }
                                }}
                            >

                                <Tab
                                    label={`Task Chat${totalUnreadTasks > 0 ? ` (${totalUnreadTasks})` : ''}`}
                                    value="taskChat"
                                    style={{
                                        color: activeTab === 'taskChat' ? 'black' : 'grey',
                                        paddingLeft: '10px',
                                        paddingRight: '10px'
                                    }}
                                />
                                <Tab
                                    label={`Done ${totalUnreadArchive > 0 ? ` (${totalUnreadArchive})` : ''}`}
                                    value="archive"
                                    style={{
                                        color: activeTab === 'archive' ? 'black' : 'grey',
                                        paddingLeft: '10px',
                                        paddingRight: '10px'
                                    }}
                                />
                                <Tab
                                    label={`General ${totalUnreadGeneral > 0 ? ` (${totalUnreadGeneral})` : ''}`}
                                    value="generalChat"
                                    style={{
                                        color: activeTab === 'generalChat' ? 'black' : 'grey',
                                        paddingLeft: '10px',
                                        paddingRight: '10px'
                                    }}
                                />
                            </Tabs>
                        </Box>

                        <div style={{overflowY: 'auto', maxHeight: 'calc(100vh - 50px)'}}>
                            {activeTab === 'taskChat' ? (hasFetched ? (<List>
                                    
                                    {pinnedTasks.map((task) => (<ListItem style={cardStyle} key={task.id}
                                                                          onClick={() => this.handleTaskClick(task)}>
                                        <TaskCard
                                            chatTask={task}
                                            unreadMessageCount={this.props.unreadMessageCount[task.id]}
                                            showControls={true}
                                        />
                                    </ListItem>))}


                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="NormalPriority">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>

                                            {nonPinnedTasks.map((task, index) => (

                                                <Draggable key={task.id} draggableId={task.id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            onClick={() => this.handleTaskClick(task)}
                                                        >

                                                            <ListItem style={cardStyle} key={task.id}
                                                                onClick={() => this.handleTaskClick(task)}>
                                                                <TaskCard
                                                                    chatTask={task}
                                                                    unreadMessageCount={this.props.unreadMessageCount[task.id]}
                                                                    showControls={true}
                                                                />
                                                            </ListItem>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                                </List>) : (<p>Loading tasks...</p>)) :


                                activeTab === 'generalChat' ? (<>


                                    {hasFetchedMembers ? (<List>
                                            <ListItem style={cardStyle} onClick={() => this.handleGeneralClick(GENERAL)}>
                                                <CardContent style={{display: 'flex', alignItems: 'center',}}>
                                                    <div>
                                                        <Typography>General</Typography>
                                                    </div>
                                                    {/* Display unread message count for the general key */}
                                                    <div style={{
                                                        marginLeft: 'auto',
                                                        width: 20,
                                                        height: 20,
                                                        background: this.props.unreadMessageCount[GENERAL] ? '#2F98A3' : 'transparent',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        {this.props.unreadMessageCount[GENERAL] > 0 && (<div style={{
                                                            color: 'white',
                                                            fontSize: 12,
                                                            fontFamily: 'Manrope',
                                                            fontWeight: '500',
                                                            lineHeight: '20px',
                                                            wordWrap: 'break-word'
                                                        }}>
                                                            {this.props.unreadMessageCount[GENERAL]}
                                                        </div>)}
                                                    </div>
                                                </CardContent>
                                            </ListItem>
                                            <ListItem style={cardStyle} onClick={() => this.handleGeneralClick(NOTIFICATIONS)}>
                                                <CardContent style={{display: 'flex', alignItems: 'center',}}>
                                                    <div>
                                                        <Typography>Notification</Typography>
                                                    </div>
                                                   
                                                </CardContent>
                                            </ListItem>
                                            {members.map((member) => (<ListItem key={member.ID}
                                                                                onClick={() => this.handleGeneralClick(member)}
                                                                                style={cardStyle}>
                                                <CardContent style={{display: 'flex', alignItems: 'center'}}>
                                                    <div>
                                                        <Typography>{member.Name}</Typography>
                                                    </div>
                                                    {/* Display unread message count for each member */}
                                                    <div style={{
                                                        marginLeft: 'auto',
                                                        width: 20,
                                                        height: 20,
                                                        background: this.props.unreadMessageCount[`mem_${member.ID}`] ? '#2F98A3' : 'transparent',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        {this.props.unreadMessageCount[`mem_${member.ID}`] > 0 && (
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 12,
                                                                fontFamily: 'Manrope',
                                                                fontWeight: '500',
                                                                lineHeight: '20px',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                {this.props.unreadMessageCount[`mem_${member.ID}`]}
                                                            </div>)}
                                                    </div>
                                                </CardContent>
                                            </ListItem>))}
                                        </List>

                                    ) : (<p>Loading family members...</p>)}
                                </>) : (hasFetchedArchive ? (

                                    <div>
                                        {archivedTasks.map((task) => (<ListItem style={cardStyle} key={task.id}
                                                                                onClick={() => this.handleTaskClick(task)}>
                                            <TaskCard
                                                chatTask={task}
                                                unreadMessageCount={this.props.unreadMessageCount[task.id]}
                                                showControls={true}
                                            />
                                        </ListItem>))}
                                    </div>) : (<p>Loading archived tasks...</p>))

                            }
                        </div>
                    </Paper>
                </div>
            </>


        );
    }
}

export default Sidebar;
